






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class LinkButton extends Vue {
  @Prop({ default: false })
  private readonly external!: boolean

  @Prop({ required: true })
  private readonly to!: string

  @Prop({ default: false })
  private readonly disabled!: boolean

  navigate (): void {
    if (this.external) {
      window.open(this.to, '_blank')
    } else if (this.$route.path !== this.to) {
      this.$router.push(this.to)
    }
  }
}
